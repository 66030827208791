import { EuiText } from '@elastic/eui';
import { TextSize } from '@elastic/eui/src/components/text/text';
import { Flex } from './flex';
import { MissingDataTooltip } from './missing-data-tooltip';

export interface LabelOrMissingProps {
  name: string;
  value?: string | number | null;
  size?: TextSize;
  type?: 'Icon' | 'Text';
}

export const LabelOrMissing = (props: LabelOrMissingProps) => {
  const { name, value, size, type } = props;
  return (
    <Flex align="center">
      <EuiText size={size} color="subdued">
        {name}:{' '}
      </EuiText>
      <div style={{ marginRight: 8 }} />
      {value ? (
        <EuiText size={size}>{value}</EuiText>
      ) : type === 'Text' ? (
        <EuiText size={size} color="subdued">
          Not recorded
        </EuiText>
      ) : (
        <MissingDataTooltip />
      )}
    </Flex>
  );
};
