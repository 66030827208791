import { EuiPanel, EuiToolTip } from '@elastic/eui';
import { Media } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Flex } from '../common';
import { MediaPreview } from './media-preview';

export interface MediaEditDiffProps {
  addedMedia: Media[];
  deletedMedia: Media[];
}

export const MediaEditDiff = observer((props: MediaEditDiffProps) => {
  const { addedMedia, deletedMedia } = props;

  return (
    <Flex column gap={8}>
      {addedMedia.map((added) => (
        <EuiToolTip key={added.id} title="Added">
          <EuiPanel
            color="success"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <MediaPreview media={added} />
          </EuiPanel>
        </EuiToolTip>
      ))}

      {deletedMedia.map((deleted) => (
        <EuiToolTip key={deleted.id} title="Deleted">
          <EuiPanel
            color="danger"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <MediaPreview media={deleted} />
          </EuiPanel>
        </EuiToolTip>
      ))}
    </Flex>
  );
});
