import { EuiBadge, EuiFlexGrid, EuiText, EuiTitle } from '@elastic/eui';
import { CopDepartmentDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks';
import { camelToSentence, dateUtils } from '../../utils';
import { Flex, LabelOrMissing } from '../common';

const CopDepartmentInfo = observer(
  ({ copDepartment }: { copDepartment: CopDepartmentDto }) => {
    const { departmentStore } = useStores();

    return (
      <Flex column>
        <EuiText>
          {departmentStore.data.getItem(copDepartment.departmentId)?.name}
        </EuiText>
        <LabelOrMissing
          name="Start date"
          value={
            copDepartment.startDate
              ? dateUtils.format(copDepartment.startDate, 'L')
              : null
          }
          type="Text"
        />
        <LabelOrMissing
          name="End date"
          value={
            copDepartment.endDate
              ? dateUtils.format(copDepartment.endDate, 'L')
              : null
          }
          type="Text"
        />
        <LabelOrMissing
          name="Position"
          value={copDepartment.position}
          type="Text"
        />
        <LabelOrMissing
          name="Badge"
          value={copDepartment.badgeNumber}
          type="Text"
        />
        {copDepartment.endDate ? (
          <LabelOrMissing
            name="Separation reason"
            value={
              copDepartment.separationReason
                ? camelToSentence(copDepartment.separationReason?.toString())
                : null
            }
            type="Text"
          />
        ) : null}
        {copDepartment.notabilities?.length ? (
          <Flex gap={8} align="center">
            <EuiText color="subdued">Notabilities:</EuiText>
            <Flex gap={4}>
              {copDepartment.notabilities.map((notability) => (
                <EuiBadge key={notability} color="hollow">
                  {camelToSentence(notability)}
                </EuiBadge>
              ))}
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    );
  },
);

export interface DepartmentEditDiffProps {
  beforeDepartments: CopDepartmentDto[];
  afterDepartments: CopDepartmentDto[];
}

export const DepartmentEditDiff = observer((props: DepartmentEditDiffProps) => {
  const { beforeDepartments, afterDepartments } = props;

  return (
    <EuiFlexGrid columns={2} gutterSize="s">
      <div>
        <EuiTitle size="xxs">
          <h4>Current</h4>
        </EuiTitle>
        <Flex column gap={8}>
          {beforeDepartments.map((copDepartment, index) => (
            <CopDepartmentInfo
              key={`${copDepartment.departmentId}-${index}`}
              copDepartment={copDepartment}
            />
          ))}
        </Flex>
      </div>
      <div>
        <EuiTitle size="xxs">
          <h4>Updated</h4>
        </EuiTitle>
        <Flex column gap={8}>
          {afterDepartments.map((copDepartment, index) => (
            <CopDepartmentInfo
              key={`${copDepartment.departmentId}-${index}`}
              copDepartment={copDepartment}
            />
          ))}
        </Flex>
      </div>
    </EuiFlexGrid>
  );
});
