import { CopDepartmentDto, CopDepartmentResponseDto } from '@unfrl/copdb-sdk';
import { last, sortBy } from 'lodash';
import moment from 'moment';
import { removeAtIndex } from './array.utils';
import { dateUtils } from './date.utils';

type CopDepartmentLike = {
  startDate?: Date | null;
  endDate?: Date | null;
  departmentId: string;
};

export const getMostRecentDepartment = <T extends CopDepartmentLike>(
  copDepartments: T[],
  departmentIdFilter?: string,
): T | undefined => {
  if (!copDepartments?.length) {
    return;
  }

  let relevantDepartments = departmentIdFilter
    ? copDepartments.filter((dep) => dep.departmentId === departmentIdFilter)
    : copDepartments;

  if (!relevantDepartments.length) {
    return;
  }

  const currentDepartments = relevantDepartments.filter((dep) => !dep.endDate);
  if (currentDepartments.length) {
    return currentDepartments[0];
  }

  const mostRecentDepartments = sortBy(
    relevantDepartments.filter((dep) => dep.startDate),
    (dep) => dep.startDate,
  );

  return mostRecentDepartments.length
    ? last(mostRecentDepartments)
    : relevantDepartments[0];
};

export const getDepartmentStatusText = (
  copDepartments: CopDepartmentResponseDto[],
  departmentIdFilter?: string,
): string | null => {
  const mostRecentDepartment = getMostRecentDepartment(
    copDepartments,
    departmentIdFilter,
  );

  if (!mostRecentDepartment) {
    return null;
  }

  return (
    mostRecentDepartment.name +
    (mostRecentDepartment.endDate ? ' (Previously)' : '')
  );
};

export const getPositionText = (
  copDepartments: CopDepartmentDto[] | null | undefined,
  departmentIdFilter?: string,
): string | null => {
  const mostRecentDepartment = getMostRecentDepartment(
    copDepartments ?? [],
    departmentIdFilter,
  );

  if (!mostRecentDepartment?.position) {
    return null;
  }

  return (
    mostRecentDepartment.position +
    (mostRecentDepartment.endDate ? ' (Previously)' : '')
  );
};

export const getBadgeNumberText = (
  copDepartments: CopDepartmentDto[] | null | undefined,
  departmentIdFilter?: string,
): string | null => {
  const mostRecentDepartment = getMostRecentDepartment(
    copDepartments ?? [],
    departmentIdFilter,
  );

  if (!mostRecentDepartment?.badgeNumber) {
    return null;
  }

  return (
    mostRecentDepartment.badgeNumber +
    (mostRecentDepartment.endDate ? ' (Previously)' : '')
  );
};

/**
 * Validates each `copDepartmentDto` in the provided array, returning a new
 * array containing an array of errors corresponding with the index of the
 * provided dto list.
 */
export const validateCopDepartmentDtos = (
  copDepartmentDtos: CopDepartmentDto[],
): string[][] => {
  const now = moment();

  return copDepartmentDtos.map((copDepartmentDto, index) => {
    const errors: string[] = [];
    const startDate = dateUtils.toMoment(copDepartmentDto.startDate);
    const endDate = dateUtils.toMoment(copDepartmentDto.endDate);

    if (startDate && startDate > now) {
      errors.push('Start date cannot be in the future');
    }

    if (endDate && endDate > now) {
      errors.push('End date cannot be in the future');
    }

    if (startDate && endDate && startDate > endDate) {
      errors.push('Start date cannot be after end date');
    }

    if (
      sameDepartmentsHaveOverlappingDates(
        copDepartmentDto,
        // check against all other cop departments except this one
        removeAtIndex(copDepartmentDtos, index),
      )
    ) {
      errors.push('One or more of the same departments have overlapping dates');
    }

    return errors;
  });
};

function sameDepartmentsHaveOverlappingDates(
  copDepartmentToCheck: CopDepartmentDto,
  otherCopDepartments: CopDepartmentDto[],
): boolean {
  // find all other same departments
  const sameCopDepartments = otherCopDepartments.filter(
    (other) => other.departmentId === copDepartmentToCheck.departmentId,
  );
  if (!sameCopDepartments.length) {
    return false;
  }

  return sameCopDepartments.some((same) =>
    dateUtils.dateRangesOverlap(same, copDepartmentToCheck),
  );
}

export const mapToCopDepartmentDto = (
  responseDtos: CopDepartmentResponseDto[],
): CopDepartmentDto[] => {
  return responseDtos.map((response) => ({
    departmentId: response.departmentId,
    startDate: response.startDate,
    endDate: response.endDate,
    badgeNumber: response.badgeNumber,
    position: response.position,
    notabilities: response.notabilities,
    separationReason: response.separationReason,
  }));
};
