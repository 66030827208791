/**
 * Removes item from index of array, returning the updated array. Does not
 * modify the passed in array.
 */
export function removeAtIndex<T>(arr: T[], index: number): T[] {
  if (index < 0 || index >= arr.length) {
    throw new Error('index is out of bounds');
  }

  const updated = [...arr];
  updated.splice(index, 1);

  return updated;
}

/**
 * Updates the array at provided index with the provided value, returning the
 * updated array. Does not modify the passed in array.
 */
export function updateAtIndex<T>(arr: T[], index: number, value: T): T[] {
  if (index < 0 || index >= arr.length) {
    throw new Error('index is out of bounds');
  }

  const updated = [...arr];
  updated[index] = value;

  return updated;
}
