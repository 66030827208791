import { formatDate } from '@elastic/eui';
import moment from 'moment';

const format = (date: Date, option?: string) => {
  return formatDate(date, option ?? 'MMM D, YYYY - LT');
};

const fromNow = (date: Date) => {
  return moment(date).fromNow();
};

const toMoment = (date?: Date | null) => {
  return date ? moment(date) : null;
};

const hasValue = (date?: Date | null) => {
  return moment.isDate(date) && !moment(date).isSame('0001-01-01T00:00:00');
};

interface DateRange {
  startDate?: Date | null;
  endDate?: Date | null;
}

// https://stackoverflow.com/a/325964/3757297
function dateRangesOverlap(rangeOne: DateRange, rangeTwo: DateRange): boolean {
  return (
    (!rangeOne.startDate ||
      !rangeTwo.endDate ||
      rangeOne.startDate <= rangeTwo.endDate) &&
    (!rangeOne.endDate ||
      !rangeTwo.startDate ||
      rangeOne.endDate >= rangeTwo.startDate)
  );
}

/**
 * Simple wrapper for moment to keep consistent styling and only the necessary
 * functionality.
 */
export const dateUtils = {
  format,
  fromNow,
  toMoment,
  hasValue,
  dateRangesOverlap,
};
