import {
  EuiFlexGrid,
  EuiHorizontalRule,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from '@elastic/eui';
import {
  CopDepartmentDto,
  CopDepartmentResponseDto,
  CopEdit,
  CopResponseDto,
  EditStatus,
  Media,
  Operation,
  PersonMediaResponseDto,
  UpsertCopDto,
} from '@unfrl/copdb-sdk';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStores } from '../../hooks';
import {
  ALL_UPSERT_COP_PROPERTIES,
  camelToSentence,
  dateUtils,
  formatEditCitation,
  formatNameFromResponseDto,
  logger,
  mapResponseToUpsertDto,
  mapToCopDepartmentDto,
} from '../../utils';
import { Flex, TextDiff } from '../common';
import { DepartmentEditDiff } from '../department';
import { MediaEditDiff } from '../media';
import { EditAction, EditActions } from '../moderate';
import { CopAvatar } from './cop-avatar';
import { CopEditForm } from './cop-edit-form';

export interface CopEditItemProps {
  cop: CopResponseDto;
  edit: CopEdit;
  existingPersonMedias: PersonMediaResponseDto[];
  existingCopDepartments: CopDepartmentResponseDto[];
}

export const CopEditItem = observer((props: CopEditItemProps) => {
  const { cop, edit, existingPersonMedias, existingCopDepartments } = props;
  const { copEditStore, dialogStore, toastStore, mediaStore, authStore } =
    useStores();
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const disableApproveReason =
    !authStore.isAdmin && authStore.user?.id === edit.createdBy
      ? 'Non-Admin users cannot approve edits they created'
      : undefined;

  const { editDto } = edit;
  const originalDto = mapResponseToUpsertDto(cop);

  let editedPhotoId = '';

  const newProfilePhoto = edit.editDto.personMediaDtos?.find(
    (photo) => photo.profilePhoto,
  );
  const originalProfilePhoto = existingPersonMedias.find(
    (photo) => photo.profilePhoto,
  );
  if (
    newProfilePhoto?.mediaId &&
    newProfilePhoto?.mediaId !== originalProfilePhoto?.mediaId
  ) {
    editedPhotoId = newProfilePhoto.mediaId;
  }

  const handleUpdateStatus = async (status: EditStatus) => {
    try {
      setSaving(true);
      await copEditStore.updateEditStatus(edit.id, status);
    } catch (error: any) {
      logger.error('handleUpdateStatus', error);
      await toastStore.showApiError(error);
    } finally {
      setSaving(false);
    }
  };

  const handleOverwrite = async (dto: UpsertCopDto) => {
    try {
      setSaving(true);
      await copEditStore.overwriteCopEdit(cop.id, dto);
      handleToggleOverwrite();
    } catch (error: any) {
      logger.error('handleOverwrite', error);
      await toastStore.showApiError(error);
    } finally {
      setSaving(false);
    }
  };

  const handleToggleOverwrite = () => {
    setEditing(!editing);
  };

  const handleConfirmOverwrite = (dto: UpsertCopDto) => {
    dialogStore.openDialog({
      title: 'Overwrite this edit?',
      content:
        'This will apply your changes and remove all other remaining edits.',
      confirmText: 'Overwrite',
      onConfirm: () => handleOverwrite(dto),
    });
  };

  const handleAction = (action: EditAction) => {
    switch (action) {
      case EditAction.Approve:
        return handleUpdateStatus(EditStatus.Approved);
      case EditAction.Deny:
        return handleUpdateStatus(EditStatus.Denied);
      case EditAction.Overwrite:
        return handleToggleOverwrite();
    }
  };

  return (
    <EuiPanel hasShadow={false} color="subdued">
      <Flex column gap={4}>
        {editedPhotoId ? (
          <div>
            <EuiText color="subdued">Profile photo:</EuiText>
            <CopAvatar
              size="s"
              name={formatNameFromResponseDto(cop)}
              photoUrl={mediaStore.data.getItem(editedPhotoId)?.url}
            />
          </div>
        ) : null}
        {ALL_UPSERT_COP_PROPERTIES.map((objKey) => {
          if (objKey === 'copDepartmentDtos') {
            const beforeDepartments: CopDepartmentDto[] = mapToCopDepartmentDto(
              existingCopDepartments,
            );
            const afterDepartments: CopDepartmentDto[] = editDto[objKey] ?? [];

            if (isEqual(beforeDepartments, afterDepartments)) {
              return null;
            }

            return (
              <div key={objKey}>
                <EuiText color="subdued">Departments:</EuiText>
                <div style={{ paddingLeft: 12 }}>
                  <DepartmentEditDiff
                    beforeDepartments={beforeDepartments}
                    afterDepartments={afterDepartments}
                  />
                </div>
              </div>
            );
          }

          if (objKey === 'personMediaDtos') {
            const upsertPersonMediaDtos = editDto.personMediaDtos ?? [];
            const addedMedia = upsertPersonMediaDtos
              .filter(
                (pmdto) =>
                  pmdto.operation !== Operation.Delete &&
                  !existingPersonMedias.find(
                    (pm) => pm.mediaId === pmdto.mediaId,
                  ),
              )
              .map((pmdto) => mediaStore.data.getItem(pmdto.mediaId))
              .filter(Boolean) as Media[];

            const deletedMedia = upsertPersonMediaDtos
              .filter(
                (pmdto) =>
                  pmdto.operation === Operation.Delete &&
                  existingPersonMedias.find(
                    (pm) => pm.mediaId === pmdto.mediaId,
                  ),
              )
              .map((pmdto) => mediaStore.data.getItem(pmdto.mediaId))
              .filter(Boolean) as Media[];

            if (!addedMedia.length && !deletedMedia.length) {
              return null;
            }

            return (
              <div key={objKey}>
                <EuiText color="subdued">Media:</EuiText>
                <MediaEditDiff
                  addedMedia={addedMedia}
                  deletedMedia={deletedMedia}
                />
              </div>
            );
          }

          if (objKey === 'visibleTattoos') {
            let before = String(originalDto[objKey] || false);
            let after = String(editDto[objKey] || false);

            if (before === after) {
              return null;
            }

            return before !== after ? (
              <Flex wrap gap={8} align="center" key={objKey}>
                <EuiText color="subdued">Visible tattoos:</EuiText>
                <EuiTextColor color="danger">{before}</EuiTextColor>
                <EuiTextColor color="success">{after}</EuiTextColor>
              </Flex>
            ) : null;
          }

          if (objKey === 'aliases') {
            const before = originalDto.aliases ?? [];
            const after = editDto.aliases ?? [];

            if (isEqual(before, after)) {
              return null;
            }

            return (
              <div key={objKey}>
                <EuiText color="subdued">Aliases:</EuiText>
                <EuiFlexGrid
                  columns={2}
                  gutterSize="s"
                  style={{ paddingLeft: 12 }}
                >
                  <div>
                    <EuiTitle size="xxs">
                      <h4>Current</h4>
                    </EuiTitle>
                    {before.length ? (
                      <EuiText>{before.join(', ')}</EuiText>
                    ) : (
                      <EuiText color="subdued">None</EuiText>
                    )}
                  </div>
                  <div>
                    <EuiTitle size="xxs">
                      <h4>Updated</h4>
                    </EuiTitle>
                    <EuiText>{after.join(', ')}</EuiText>
                  </div>
                </EuiFlexGrid>
              </div>
            );
          }

          const before = originalDto[objKey] as string;
          const after = editDto[objKey] as string;
          if (before === after) {
            return null;
          }

          const camelCaseValues: string[] = [
            'hairColor',
            'eyeColor',
            'gender',
            'race',
          ];
          if (camelCaseValues.includes(objKey)) {
            return (
              <TextDiff
                key={objKey}
                beforeText={camelToSentence(before)}
                afterText={camelToSentence(after)}
                label={camelToSentence(objKey)}
              />
            );
          }

          return (
            <TextDiff
              key={objKey}
              beforeText={before}
              afterText={after}
              label={camelToSentence(objKey)}
            />
          );
        })}
      </Flex>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="s" />
      <Flex justify="space-between" align="center">
        <EuiText
          color="subdued"
          size="s"
          title={dateUtils.format(edit.createdAt)}
        >
          {formatEditCitation(edit)}
        </EuiText>
        <EditActions
          onAction={handleAction}
          disableApproveReason={disableApproveReason}
        />
      </Flex>
      {editing && (
        <CopEditForm
          title="Overwrite edit"
          copDto={editDto}
          onClose={handleToggleOverwrite}
          onSave={handleConfirmOverwrite}
          saving={saving}
        />
      )}
    </EuiPanel>
  );
});
