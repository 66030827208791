import { EuiFormRow, EuiSpacer } from '@elastic/eui';
import { CopDepartmentDto, DepartmentResponseDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment, useEffect, useState } from 'react';
import { useStores } from '../../hooks';
import {
  removeAtIndex,
  updateAtIndex,
  validateCopDepartmentDtos,
} from '../../utils';
import { DepartmentSearch } from '../department';
import { CopDepartmentItem } from './cop-department-item';

export interface CopDepartmentsInputProps {
  copDepartmentDtos: CopDepartmentDto[];
  onChange: (dtos: CopDepartmentDto[]) => void;
}

export const CopDepartmentsInput = observer(
  (props: CopDepartmentsInputProps) => {
    const { copDepartmentDtos, onChange } = props;
    const { departmentStore } = useStores();

    const [departmentErrors, setDepartmentErrors] = useState<string[][]>([]);

    const validateDepartmentDates = () => {
      const errors = validateCopDepartmentDtos(copDepartmentDtos);
      setDepartmentErrors(errors);
    };

    useEffect(() => {
      if (copDepartmentDtos?.length) {
        departmentStore.fetchDepartments(
          copDepartmentDtos.map((dto) => dto.departmentId),
        );
      }
      validateDepartmentDates();
    }, [copDepartmentDtos]);

    const handleSelectDepartment = (department: DepartmentResponseDto) => {
      onChange([...copDepartmentDtos, { departmentId: department.id }]);
    };

    const handleUpdateDepartment = (
      index: number,
      copDepartmentDto: CopDepartmentDto,
    ) => {
      onChange(updateAtIndex(copDepartmentDtos, index, copDepartmentDto));
    };

    const handleRemoveDepartment = (index: number) => {
      onChange(removeAtIndex(copDepartmentDtos, index));
    };

    const renderDepartmentItems = () => {
      if (!copDepartmentDtos.length) {
        return null;
      }

      return (
        <Fragment>
          <EuiSpacer size="m" />
          {copDepartmentDtos.map((copDepartmentDto, index) => {
            const departmentName =
              departmentStore.data.getItem(copDepartmentDto.departmentId)
                ?.name ?? '';
            const errors = departmentErrors[index] ?? [];
            const isInvalid = !!errors.length;

            return (
              <EuiFormRow
                fullWidth
                key={`${copDepartmentDto.departmentId}-${index}`}
                isInvalid={isInvalid}
                error={errors}
              >
                <CopDepartmentItem
                  departmentName={departmentName}
                  copDepartmentDto={copDepartmentDto}
                  onUpdate={(updated) => handleUpdateDepartment(index, updated)}
                  onRemove={() => handleRemoveDepartment(index)}
                  isInvalid={isInvalid}
                />
              </EuiFormRow>
            );
          })}
        </Fragment>
      );
    };

    return (
      <Fragment>
        <EuiFormRow
          fullWidth
          label="Departments"
          helpText="Search for any departments this cop is, or has been, a part of. Please specify their start and end dates if you know them."
        >
          <DepartmentSearch onSelected={handleSelectDepartment} />
        </EuiFormRow>
        {renderDepartmentItems()}
      </Fragment>
    );
  },
);
