import {
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiDescriptionList,
  EuiFieldNumber,
  EuiMarkdownFormat,
  EuiScreenReaderOnly,
  EuiText,
} from '@elastic/eui';
import {
  CopDepartmentDto,
  IncidentInformationDto,
  SeparationReason,
  UpsertCopDto,
} from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useExpandedMap, useStores } from '../../hooks';
import {
  camelToSentence,
  formatNameFromUpsertDto,
  getMostRecentDepartment,
  getProfilePhotoFromUpsert,
  logger,
} from '../../utils';
import { Flex, LabelOrMissing, MissingDataText } from '../common';
import { CopAvatar } from '../cop';

const NewCopAvatar = observer(({ cop }: { cop: UpsertCopDto }) => {
  const name = formatNameFromUpsertDto(cop);
  return (
    <Flex align="center" gap={12}>
      <CopAvatar
        name={name}
        photoUrl={getProfilePhotoFromUpsert(cop)}
        size={64}
      />
      <div>
        <EuiText size="relative">{name}</EuiText>
        {cop.aliases?.length ? (
          <EuiText size="relative" color="subdued">
            {cop.aliases.join(', ')}
          </EuiText>
        ) : null}
      </div>
    </Flex>
  );
});

/**
 * Note: the reason this component requires a `getCop` function, is because it's being
 * used in the expanded map hook. If its data is updated, in order for this
 * component to reflect the changes, a function prop needed to be used:
 * https://mobx.js.org/react-optimizations.html#function-props-
 */
const NewCopDetails = observer(({ getCop }: { getCop: () => UpsertCopDto }) => {
  const { departmentStore } = useStores();

  const cop = getCop();

  return (
    <EuiDescriptionList
      style={{ padding: 8 }}
      rowGutterSize="m"
      listItems={[
        {
          title: 'Description',
          description: (
            <EuiMarkdownFormat textSize="relative">
              {cop.description ?? 'N/A'}
            </EuiMarkdownFormat>
          ),
        },
        {
          title: 'Demographic information',
          description: (
            <Flex column>
              <LabelOrMissing
                name="Eye color"
                value={cop.eyeColor}
                size="relative"
                type="Text"
              />
              <LabelOrMissing
                name="Hair color"
                value={cop.hairColor}
                size="relative"
                type="Text"
              />
              <LabelOrMissing
                name="Gender"
                value={cop.gender}
                size="relative"
                type="Text"
              />
              <LabelOrMissing
                name="Race"
                value={cop.race}
                size="relative"
                type="Text"
              />
              <LabelOrMissing
                name="Visible tattoos"
                value={
                  cop.visibleTattoos != null
                    ? cop.visibleTattoos
                      ? 'Yes'
                      : 'No'
                    : undefined
                }
                size="relative"
                type="Text"
              />
            </Flex>
          ),
        },
        {
          title: 'Department history',
          description: (
            <EuiBasicTable
              columns={[
                {
                  name: 'Name',
                  render: (department: CopDepartmentDto) =>
                    departmentStore.data.getItem(department.departmentId)?.name,
                },
                {
                  name: 'Start date',
                  field: 'startDate',
                  render: (startDate?: Date) =>
                    startDate
                      ? new Date(startDate).toLocaleDateString()
                      : 'N/A',
                },
                {
                  name: 'End date',
                  field: 'endDate',
                  render: (endDate?: Date) =>
                    endDate ? new Date(endDate).toLocaleDateString() : 'N/A',
                },
                {
                  name: 'Position',
                  field: 'position',
                  render: (position?: string) => position ?? 'N/A',
                },
                {
                  name: 'Badge number',
                  field: 'badgeNumber',
                  render: (badgeNumber?: string) => badgeNumber ?? 'N/A',
                },
                {
                  name: 'Separation reason',
                  field: 'separationReason',
                  render: (separationReason?: SeparationReason) =>
                    separationReason
                      ? camelToSentence(separationReason.toString())
                      : 'N/A',
                },
                {
                  name: 'Notabilities',
                  render: (department: CopDepartmentDto) => {
                    if (!department.notabilities?.length) {
                      return 'N/A';
                    }

                    return (
                      <Flex wrap gap={4}>
                        {department.notabilities.map((notability) => (
                          <Flex key={notability}>
                            <EuiBadge>{camelToSentence(notability)}</EuiBadge>
                          </Flex>
                        ))}
                      </Flex>
                    );
                  },
                },
              ]}
              items={cop.copDepartmentDtos ?? []}
            />
          ),
        },
      ]}
    />
  );
});

type CopDtoWithIndex = { index: number } & UpsertCopDto;

export interface NewCopsTableProps {
  /**
   * List of new cops to render in a table.
   */
  cops: UpsertCopDto[];
  /**
   * Optional edit actions. It not provided, table will be rendered in a
   * readonly mode.
   */
  editActions?: {
    onDelete: (index: number) => void;
    onEdit: (index: number) => void;
    onEditIncidentInfo: (
      index: number,
      incidentInfo: IncidentInformationDto,
    ) => void;
  };
}

export const NewCopsTable = observer((props: NewCopsTableProps) => {
  const { cops, editActions } = props;
  const { departmentStore, toastStore } = useStores();
  const [loadingDepartments, setLoadingDepartments] = useState(false);

  const { expandedMap, isExpanded, toggleExpanded } = useExpandedMap((id) => {
    return <NewCopDetails getCop={() => cops[parseInt(id)]} />;
  });

  useEffect(() => {
    const loadDepartments = async () => {
      try {
        setLoadingDepartments(true);

        const departmentIds = new Set(
          cops
            .flatMap((cop) => cop.copDepartmentDtos ?? [])
            .map((copDepartment) => copDepartment.departmentId),
        );

        await departmentStore.fetchDepartments([...departmentIds.values()]);
      } catch (error) {
        logger.error('error loading departments', error);
        await toastStore.showApiError(error);
      } finally {
        setLoadingDepartments(false);
      }
    };

    loadDepartments();
  }, [cops, departmentStore, toastStore]);

  if (!cops.length) {
    return (
      <EuiText color="subdued" size="relative">
        No cops recorded.
      </EuiText>
    );
  }

  // we map the cop dto with its index for edit and delete operations since we
  // don't have an ID to reference
  const copDtosWithIndex: CopDtoWithIndex[] = cops.map((cop, index) => ({
    index,
    ...cop,
  }));

  const columns: EuiBasicTableColumn<CopDtoWithIndex>[] = [
    {
      name: 'Name',
      render: (cop: UpsertCopDto) => <NewCopAvatar cop={cop} />,
      width: '250px',
    },
    {
      name: 'Department',
      render: (cop: UpsertCopDto) => {
        const current = getMostRecentDepartment(cop.copDepartmentDtos ?? []);
        if (!current) {
          return <MissingDataText text="N/A" />;
        }

        return departmentStore.data.getItem(current.departmentId)?.name;
      },
    },
    {
      name: 'Position',
      render: (cop: UpsertCopDto) => {
        const current = getMostRecentDepartment(cop.copDepartmentDtos ?? []);
        return current?.position ?? <MissingDataText text="N/A" />;
      },
    },
    {
      name: 'Badge number',
      render: (cop: UpsertCopDto) => {
        const current = getMostRecentDepartment(cop.copDepartmentDtos ?? []);
        return current?.badgeNumber ?? <MissingDataText text="N/A" />;
      },
    },
    {
      name: 'Shots fired',
      width: '120px',
      render: (cop: CopDtoWithIndex) => {
        if (!editActions) {
          return (
            cop.incidentInformation?.shotsFired ?? (
              <MissingDataText text="N/A" />
            )
          );
        }

        return (
          <EuiFieldNumber
            aria-label="Enter the number of shots fired"
            value={cop.incidentInformation?.shotsFired ?? ''}
            min={0}
            onChange={(e) => {
              editActions.onEditIncidentInfo(cop.index, {
                ...cop.incidentInformation,
                shotsFired: e.target.value
                  ? Number.parseInt(e.target.value)
                  : undefined,
              });
            }}
          />
        );
      },
    },
  ];

  if (editActions) {
    columns.push({
      name: 'Actions',
      actions: [
        {
          name: 'Edit',
          description: 'Edit cop details',
          type: 'icon',
          icon: 'pencil',
          onClick: (copDto: CopDtoWithIndex) => {
            editActions.onEdit(copDto.index);
          },
        },
        {
          name: 'Delete',
          description: 'Delete new cop',
          type: 'icon',
          icon: 'trash',
          color: 'danger',
          onClick: (copDto: CopDtoWithIndex) => {
            editActions.onDelete(copDto.index);
          },
        },
      ],
    });
  }

  return (
    <EuiBasicTable
      columns={[
        ...columns,
        {
          align: 'right',
          width: '40px',
          isExpander: true,
          name: (
            <EuiScreenReaderOnly>
              <span>Expand row</span>
            </EuiScreenReaderOnly>
          ),
          mobileOptions: { header: false },
          render: ({ index }: CopDtoWithIndex) => {
            const indexAsId = index.toString();
            const expanded = isExpanded(indexAsId);

            return (
              <EuiButtonIcon
                onClick={() => toggleExpanded(indexAsId)}
                aria-label={expanded ? 'Collapse' : 'Expand'}
                iconType={expanded ? 'arrowDown' : 'arrowRight'}
              />
            );
          },
        },
      ]}
      items={copDtosWithIndex}
      itemIdToExpandedRowMap={expandedMap}
      itemId="index"
      loading={loadingDepartments}
    />
  );
});
