import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldText,
  EuiInlineEditText,
} from '@elastic/eui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { removeAtIndex, updateAtIndex } from '../../utils';
import { Flex } from './flex';

export interface StringInputListProps {
  values: string[];
  onChange: (values: string[]) => void;
  placeholder?: string;
}

export const StringInputList = observer((props: StringInputListProps) => {
  const { values, onChange, placeholder } = props;
  const [newValue, setNewValue] = useState('');

  const trimmedNewValue = newValue.trim();
  const isNewValueValid = Boolean(
    trimmedNewValue && values.indexOf(trimmedNewValue) < 0,
  );

  const handleSaveNewValue = () => {
    if (!isNewValueValid) {
      return;
    }

    onChange([...values, trimmedNewValue]);
    setNewValue('');
  };

  const handleSaveUpdatedValue = (
    updatedValue: string,
    index: number,
  ): boolean => {
    const trimmed = updatedValue.trim();
    if (!trimmed || values.indexOf(trimmed) >= 0) {
      return false;
    }

    onChange(updateAtIndex(values, index, trimmed));
    return true;
  };

  const handleDeleteValue = (index: number) => {
    onChange(removeAtIndex(values, index));
  };

  return (
    <Flex column gap={8}>
      <Flex gap={8} align="center">
        <EuiFieldText
          placeholder={placeholder}
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSaveNewValue();
            }
          }}
        />
        <EuiButtonEmpty
          iconType="plus"
          iconSide="left"
          disabled={!isNewValueValid}
          onClick={handleSaveNewValue}
        >
          Add
        </EuiButtonEmpty>
      </Flex>
      <Flex column gap={4}>
        {values.map((value, index) => (
          <Flex key={value} justify="space-between" align="center" gap={8}>
            <EuiInlineEditText
              inputAriaLabel="Edit text value inline"
              defaultValue={value}
              onSave={(updatedValue) =>
                handleSaveUpdatedValue(updatedValue, index)
              }
              style={{ flexGrow: 1 }}
              readModeProps={{
                style: { width: '100%', paddingLeft: 8, paddingRight: 8 },
                contentProps: {
                  style: { display: 'flex', justifyContent: 'space-between' },
                },
              }}
            />
            <EuiButtonIcon
              aria-label="Delete alias"
              title="Delete alias"
              iconType="trash"
              color="danger"
              size="m"
              onClick={() => handleDeleteValue(index)}
            />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
});
