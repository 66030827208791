import {
  EuiDatePicker,
  EuiDatePickerRange,
  EuiLink,
  EuiToolTip,
} from '@elastic/eui';
import { CopDepartmentResponseDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { camelToSentence, departmentProfilePath } from '../../utils';
import { Flex, LabelOrMissing } from '../common';
import { CopNotabilityList } from './cop-notability-list';

export interface CopDepartmentListProps {
  copDepartments: CopDepartmentResponseDto[];
}

const CopDepartmentListItem = observer(
  ({ copDepartment }: { copDepartment: CopDepartmentResponseDto }) => {
    const fullStartDateText = copDepartment.startDate
      ? copDepartment.startDate.toLocaleDateString()
      : 'unknown';
    const fullEndDateText = copDepartment.endDate
      ? copDepartment.endDate.toLocaleDateString()
      : 'now';
    return (
      <Flex wrap justify="space-between" align="center" gap={16}>
        <Flex column gap={4}>
          <Link to={departmentProfilePath(copDepartment.departmentId)}>
            <EuiLink>{copDepartment.name}</EuiLink>
          </Link>
          <LabelOrMissing
            size="relative"
            name="Position"
            value={copDepartment.position}
          />
          <LabelOrMissing
            size="relative"
            name="Badge"
            value={copDepartment.badgeNumber}
          />
          {copDepartment.endDate ? (
            <LabelOrMissing
              size="relative"
              name="Separation reason"
              value={
                copDepartment.separationReason
                  ? camelToSentence(copDepartment.separationReason)
                  : null
              }
            />
          ) : null}
          <CopNotabilityList notabilities={copDepartment.notabilities} />
        </Flex>

        <EuiToolTip content={`${fullStartDateText} → ${fullEndDateText}`}>
          <EuiDatePickerRange
            readOnly
            startDateControl={
              <EuiDatePicker
                dateFormat="YYYY"
                aria-label="Start date"
                value={
                  copDepartment.startDate
                    ? copDepartment.startDate.getFullYear().toString()
                    : 'unknown'
                }
              />
            }
            endDateControl={
              <EuiDatePicker
                aria-label="End date"
                dateFormat="YYYY"
                value={
                  copDepartment.endDate
                    ? copDepartment.endDate.getFullYear().toString()
                    : 'now'
                }
              />
            }
          />
        </EuiToolTip>
      </Flex>
    );
  },
);

export const CopDepartmentList = observer((props: CopDepartmentListProps) => {
  const { copDepartments } = props;

  return (
    <ul style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      {copDepartments.map((copDepartment, index) => (
        <CopDepartmentListItem
          key={`${copDepartment.departmentId}-${index}`}
          copDepartment={copDepartment}
        />
      ))}
    </ul>
  );
});
