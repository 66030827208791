import {
  EuiButtonIcon,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { CopDepartmentDto, SeparationReason } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { ChangeEvent } from 'react';
import { dateUtils } from '../../utils';
import { Flex, StringComboBox } from '../common';
import { CopNotabilityList } from './cop-notability-list';

export interface CopDepartmentItemProps {
  departmentName: string;
  copDepartmentDto: CopDepartmentDto;
  onRemove: () => void;
  onUpdate: (dto: CopDepartmentDto) => void;
  isInvalid: boolean;
}

export const CopDepartmentItem = observer((props: CopDepartmentItemProps) => {
  const { departmentName, copDepartmentDto, onRemove, onUpdate, isInvalid } =
    props;

  const handleTextChange =
    (key: keyof CopDepartmentDto) => (e: ChangeEvent<HTMLInputElement>) => {
      onUpdate({ ...copDepartmentDto, [key]: e.target.value });
    };

  return (
    <>
      <Flex align="center">
        <EuiText size="relative">{departmentName}</EuiText>
        <EuiFlexItem style={{ flexFlow: 'row-reverse' }}>
          <EuiButtonIcon
            iconType="trash"
            aria-label="Delete"
            color="danger"
            onClick={onRemove}
          />
        </EuiFlexItem>
      </Flex>

      <EuiSpacer size="xs" />

      <EuiFlexGrid columns={2} gutterSize="s">
        <EuiFlexItem>
          <EuiDatePicker
            maxDate={moment()}
            selected={dateUtils.toMoment(copDepartmentDto.startDate)}
            onChange={(date) =>
              onUpdate({ ...copDepartmentDto, startDate: date?.toDate() })
            }
            onClear={() =>
              onUpdate({ ...copDepartmentDto, startDate: undefined })
            }
            isInvalid={isInvalid}
            placeholder="Start date"
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiDatePicker
            maxDate={moment()}
            selected={dateUtils.toMoment(copDepartmentDto.endDate)}
            onChange={(date) =>
              onUpdate({ ...copDepartmentDto, endDate: date?.toDate() })
            }
            onClear={() =>
              onUpdate({
                ...copDepartmentDto,
                endDate: undefined,
                separationReason: undefined,
              })
            }
            isInvalid={isInvalid}
            placeholder="End date"
          />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiText color="subdued" size="xs">
            Badge number
          </EuiText>
          <EuiFieldText
            placeholder="J117"
            fullWidth
            name="badgeNumber"
            value={copDepartmentDto.badgeNumber ?? ''}
            onChange={handleTextChange('badgeNumber')}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText color="subdued" size="xs">
            Position
          </EuiText>
          <EuiFieldText
            placeholder="Sergeant"
            fullWidth
            name="position"
            value={copDepartmentDto.position ?? ''}
            onChange={handleTextChange('position')}
          />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiText color="subdued" size="xs">
            Separation reason
          </EuiText>
          <StringComboBox
            placeholder={
              copDepartmentDto.endDate
                ? 'Select an option'
                : 'Requires an end date'
            }
            isDisabled={!copDepartmentDto.endDate}
            options={Object.values(SeparationReason)}
            value={copDepartmentDto.separationReason?.toString()}
            onChange={(value) => {
              onUpdate({
                ...copDepartmentDto,
                separationReason: value
                  ? (value as SeparationReason)
                  : undefined,
              });
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText color="subdued" size="xs">
            Notability
          </EuiText>
          <CopNotabilityList
            notabilities={copDepartmentDto.notabilities}
            onChange={(values) =>
              onUpdate({
                ...copDepartmentDto,
                notabilities: values,
              })
            }
          />
        </EuiFlexItem>
      </EuiFlexGrid>
    </>
  );
});
