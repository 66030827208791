import {
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiFormRow,
  EuiMarkdownEditor,
  EuiText,
  getDefaultEuiMarkdownPlugins,
} from '@elastic/eui';
import {
  EyeColor,
  Gender,
  HairColor,
  Race,
  UpsertCopDto,
} from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { ChangeEvent, Fragment } from 'react';
import { StringComboBox, StringInputList } from '../common';

export interface CopBasicInputsProps {
  copDto: UpsertCopDto;
  onChange: (dto: UpsertCopDto) => void;
}

export const CopBasicInputs = observer((props: CopBasicInputsProps) => {
  const { copDto, onChange } = props;

  const handleTextChange =
    (key: keyof UpsertCopDto) => (e: ChangeEvent<HTMLInputElement>) => {
      onChange({ ...copDto, [key]: e.target.value });
    };

  const { parsingPlugins, processingPlugins, uiPlugins } =
    getDefaultEuiMarkdownPlugins({ exclude: ['tooltip'] });

  const mapBooleanToYesNo = (
    val: boolean | undefined | null,
  ): 'yes' | 'no' | undefined =>
    val === true ? 'yes' : val === false ? 'no' : undefined;

  return (
    <Fragment>
      <EuiFormRow fullWidth label="First name">
        <EuiFieldText
          fullWidth
          name="firstName"
          value={copDto.firstName ?? ''}
          onChange={handleTextChange('firstName')}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="Middle name">
        <EuiFieldText
          fullWidth
          name="middleName"
          value={copDto.middleName ?? ''}
          onChange={handleTextChange('middleName')}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="Last name">
        <EuiFieldText
          fullWidth
          name="lastName"
          value={copDto.lastName ?? ''}
          onChange={handleTextChange('lastName')}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="Aliases">
        <StringInputList
          values={copDto.aliases ?? []}
          onChange={(values) => onChange({ ...copDto, aliases: values })}
          placeholder="Nicknames, maiden names, etc."
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="Description">
        <EuiMarkdownEditor
          aria-label="Cop description editor"
          placeholder="Description of the cop..."
          value={copDto.description ?? ''}
          onChange={(value) => {
            onChange({ ...copDto, description: value ? value : undefined });
          }}
          height={250}
          initialViewMode="editing"
          parsingPluginList={parsingPlugins}
          processingPluginList={processingPlugins}
          uiPlugins={uiPlugins}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="Demographic information">
        <EuiFlexGrid columns={2} gutterSize="s">
          <EuiFlexItem>
            <EuiText color="subdued" size="xs">
              Eye color
            </EuiText>
            <StringComboBox
              options={Object.values(EyeColor)}
              placeholder="Eye color"
              value={copDto.eyeColor?.toString()}
              onChange={(t) => {
                onChange({
                  ...copDto,
                  eyeColor: t ? (t as EyeColor) : undefined,
                });
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText color="subdued" size="xs">
              Hair color
            </EuiText>
            <StringComboBox
              options={Object.values(HairColor)}
              placeholder="Hair color"
              value={copDto.hairColor?.toString()}
              onChange={(t) => {
                onChange({
                  ...copDto,
                  hairColor: t ? (t as HairColor) : undefined,
                });
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText color="subdued" size="xs">
              Gender
            </EuiText>
            <StringComboBox
              options={Object.values(Gender)}
              placeholder="Gender"
              value={copDto.gender?.toString()}
              onChange={(t) => {
                onChange({
                  ...copDto,
                  gender: t ? (t as Gender) : undefined,
                });
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText color="subdued" size="xs">
              Race
            </EuiText>
            <StringComboBox
              options={Object.values(Race)}
              placeholder="Race"
              value={copDto.race?.toString()}
              onChange={(t) => {
                onChange({
                  ...copDto,
                  race: t ? (t as Race) : undefined,
                });
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText color="subdued" size="xs">
              Visible tattoos
            </EuiText>
            <StringComboBox
              isClearable={false}
              options={['yes', 'no']}
              placeholder="Visible tattoos"
              value={mapBooleanToYesNo(copDto.visibleTattoos)}
              onChange={(t) => {
                onChange({
                  ...copDto,
                  visibleTattoos: t === 'yes',
                });
              }}
            />
          </EuiFlexItem>
        </EuiFlexGrid>
      </EuiFormRow>
    </Fragment>
  );
});
